"use strict";

/* eslint-disable func-names */

/* ==========================================================================
Main
========================================================================== */

/*
 * Description :
 * - Scripts de base
 * Auteur :
 * - CEF / FU
 * Historique :
 * - 2020-04-02 : Création
 * Sommaire :
 * |x| dom
 * |x| social
 * |x| buttons
 * |x| tiles
 * |x| space
 */
(function () {
  // |x| dom
  // Tous les éléments du dom nécessaires sont stockés dans cet objet
  //
  var dom = {
    //articleItems: [...document.querySelectorAll('.tiles__article__list li')], //Non compatible avec IE11 et Babel (spread sur une nodelist)
    //productItems: [...document.querySelectorAll('.tiles__product__list li')],
    articleItems: Array.prototype.slice.call(document.querySelectorAll('.tiles__article__list li')),
    productItems: Array.prototype.slice.call(document.querySelectorAll('.tiles__product__list li')),
    socialContainer: document.querySelector('.social'),
    socialButton: document.querySelector('.social__button')
  }; //console.log(dom);

  var social = {
    onClick: function onClick() {
      // Au clic sur le bouton on change de classe pour rendre visible les enfants
      dom.socialButton.addEventListener('click', function () {
        dom.socialContainer.toggleAttribute('data-open');
      });
    },
    onResize: function onResize() {
      if (window.innerWidth <= 640) {
        dom.socialContainer.dataset.enable = true;
      }

      window.addEventListener('resize', function () {
        if (window.innerWidth <= 640) {
          dom.socialContainer.dataset.enable = true;
        } else {
          dom.socialContainer.dataset.enable = false;
          dom.socialContainer.removeAttribute('data-open');
        }
      });
    },
    init: function init() {
      if (dom.socialContainer) {
        social.onResize();
        social.onClick();
      }
    }
  };
  var tiles = {
    onClick: function onClick() {
      //const allTiles = [...dom.articleItems, ...dom.productItems]; //Non compatible avec IE11 et Babel (spread sur une nodelist)
      var allTiles = dom.articleItems.concat(dom.productItems);

      if (allTiles.length > 0) {
        //console.log(allTiles);
        allTiles.forEach(function (el) {
          //console.log(el);
          el.addEventListener('click', clickableTile);

          function clickableTile(e) {
            console.log(e); //console.log(e.path[0].nodeName);

            console.log(e.target.nodeName);
            this.removeEventListener('click', clickableTile); //on supprime l'elementListner pour ne pas que ça boucle !
            //on teste si l'élément cliqué n'est pas un lien, car sinon, le lien est cliqué "2 fois" et remonte en double dans les statistiques
            //si l'élément clqué est un lien, on a rien à faire

            if (e.target.nodeName != 'A') {
              var tileLink = this.querySelector('a');
              tileLink.click();
            }
          }
        });
      }
    },
    init: function init() {
      tiles.onClick();
    }
  };
  var space = {
    onLoad: function onLoad() {
      // Legacy converted to ES6
      var space_name = '';
      var space_link = '';
      var prospection = document.querySelector('.ctxt-prospection');

      if (!prospection) {
        var p = document.querySelector('.cc_menu_espace li.e_select > a');
        var nav = document.querySelector('nav > ul > li > a');
        var q = document.querySelector('.cc_menu_espace > a');

        if (p && nav && q) {
          if (p !== '' && p !== null) {
            space_name = p.innerHTML;
            space_link = p.getAttribute('href');
          } else if (nav !== '') {
            space_name = nav.innerHTML;
            space_link = nav.getAttribute('href'); //console.log(space_link);
          }

          if (space_name !== '') {
            if (q !== '') {
              q.innerHTML = space_name;
            }
          } else {
            q.innerHTML = 'Accueil';
          }

          if (space_link !== '') {
            if (q !== '') {
              q.setAttribute('href', space_link);
            }
          } //tracking de l'utilisation du menu espace
          //const space_links = document.querySelectorAll('.cc_menu_espace .e_smenu li > a');//Non compatible avec IE11 et Babel (foreach sur une nodelist)


          var space_links = Array.prototype.slice.call(document.querySelectorAll('.cc_menu_espace .e_smenu li > a'));
          space_links.forEach(function (el) {
            el.addEventListener('click', function () {
              obj = {
                category: 'Navigation',
                action: 'Clic',
                label: '[Menu_Espaces-' + this.text + ']'
              }; //console.log(obj)

              if (typeof trackEvent != 'undefined') {
                trackEvent(obj);
              }
            });
          });
        }
      }
    },
    init: function init() {
      space.onLoad();
    }
  };
  social.init();
  tiles.init();
  space.init();
})();